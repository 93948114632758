<template>
	<div class="relative pt-40">
		<div class="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
			<div class="px-4 py-8 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
				<div class="max-w-lg mx-auto">
					<h2 class="pageheader">Get in touch</h2>
					<p class="mt-3 text-lg leading-6 text-gray-500">
						We'd love to hear from you. Please contact us using the details below or via the contact form on this page. Looking forward to connecting with you soon :)
					</p>
					<dl class="mt-8 text-base text-gray-500">
						<div class="mt-6">
							<dt class="sr-only">Phone number</dt>
							<dd class="flex">
								<PhoneIcon class="w-7 text-primary-800" />
								<a href="tel:+27-11-8377432" class="ml-3">+27 (0)11 837 7432</a>
							</dd>
						</div>
						<div class="mt-3">
							<dt class="sr-only">Email</dt>
							<dd class="flex">
								<MailIcon class="w-7 text-primary-800" />
								<a href="mailto:info@edeaf.co.za" class="ml-3">info@edeaf.co.za</a>
							</dd>
						</div>
					</dl>
				</div>
				<SocialMedia />
			</div>
			<div class="bg-gray-50 border py-16 px-4 sm:px-6 lg:col-span-3 lg:py-18 lg:px-8 xl:pl-12 shadow rounded-lg">
				<div class="max-w-lg mx-auto lg:max-w-none">
					<form id="contact" class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8" @submit.prevent="submit">
						<div class="col-span-6">
							<TextInput
								autocomplete="on"
								form="contact"
								:maxlength="150"
								:minlength="1"
								name="Name"
								placeholder="Full Name"
								type="text"
								v-model="name"
								@update:model-value="name = $event"
								class="col-span-6 sm:col-span-3"
							/>
						</div>
						<div class="col-span-6">
							<TextInput
								autocomplete="on"
								form="contact"
								:maxlength="150"
								:minlength="5"
								name="phone"
								placeholder="Contact Number"
								type="tel"
								v-model="phone"
								@update:model-value="phone = $event"
								class="col-span-6"
							/>
						</div>
						<div class="col-span-6">
							<TextInput
								autocomplete="on"
								form="contact"
								:maxlength="150"
								:minlength="5"
								name="email"
								placeholder="Email Address"
								type="email"
								v-model="email"
								@update:model-value="emailAddress = $event"
								class="col-span-6"
							/>
						</div>
						<div class="col-span-6">
							<TextInput
								autocomplete="on"
								form="contact"
								:maxlength="8000"
								:minlength="1"
								name="message"
								placeholder="Message"
								type="text"
								v-model="message"
								@update:model-value="message = $event"
								class="col-span-6 sm:col-span-3"
							/>
						</div>
						<div class="col-span-6">
							<button @click="sendMail" :disabled="disabled" :class="{ 'opacity-50 cursor-not-allowed': disabled }" class="buttonprimary px-5 py-3">Submit</button>
						</div>
						<div class="col-span-6 flex">
							<div v-if="mailSent" class=""><component :is="statusIcon" class="h-6 px-2 text-primary-900" /></div>
							<div>{{ statusMessage }}</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<Offices class="mt-3 px-4" />
</template>

<script>
import TextInput from '@/components/controls/TextInput.vue';
import SocialMedia from '@/components/SocialMedia.vue';
import { PhoneIcon, MailIcon, ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/vue/outline';
import Offices from '../components/Offices.vue';
import axios from 'axios';

export default {
	components: {
		TextInput,
		PhoneIcon,
		MailIcon,
		ExclamationCircleIcon,
		CheckCircleIcon,
		Offices,
		SocialMedia,
	},
	data: () => ({
		valid: true,
		mailSent: false,
		name: '',
		email: '',
		phone: '',
		message: '',
		disabled: false,
		statusMessage: '',
		statusIcon: '',
		lazy: false,
	}),

	methods: {
		getImgUrl(img) {
			return require('../assets/' + img);
		},
		validate() {},
		sendMail() {
			this.disabled = true;

			this.statusMessage = 'Sending mail...';

			const postData = {
				fromEmail: { key: 'nicky@edeaf.co.za', value: 'Nicky Bezuidenhout' },
				toEmail: { key: 'nicky@edeaf.co.za', value: 'Nicky Bezuidenhout' },
				bccEmail: { key: 'steve@cogni.co.za', value: 'Steve Bezuidenhout' },
				subject: 'eDeaf Website Enquiry',
				content: `Name: ${this.name}<br>Contact No: ${this.phone}<br>Email: ${this.email}<br>Message: ${this.message}`,
				source: 'eDeaf.Web',
			};

			let axiosConfig = {
				headers: {
					'Content-Type': 'application/json;charset=UTF-8',
					requestOriginKey: 'AB1BB7D0-B6C4-4BC4-BD11-3E5A48336C0D',
				},
			};

			axios
				.post('https://cognizance-api-web-app.azurewebsites.net/api/web/sendemail', postData, axiosConfig)
				.then((response) => {
					if (response.status == 200) {
						this.$gtag.event('contact-submitted', { method: 'Google' });
						this.statusMessage = 'Thanks for reaching out. We will be in contact soon :)';
						this.statusIcon = 'CheckCircleIcon';
					} else {
						this.statusMessage = 'Something went wrong :( Please contact us via other means.';
						this.statusIcon = 'ExclamationCircleIcon';
					}
					this.mailSent = true;
				})
				.catch(function (error) {
					console.log('error ' + error);
				});
		},
	},
	mounted() {
		this.$gtag.event('contact', { method: 'Google' });
	},
};
</script>
