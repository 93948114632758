<template>
	<div class="relative bg-white overflow-hidden">
		<div class="relative pt-6 pb-16 sm:pb-24 lg:pb-32">
			<main class="mt-16 mx-auto max-w-7xl px-4 sm:px-6">
				<div class="lg:grid lg:grid-cols-12 lg:gap-8">
					<div class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
						<h1>
							<span class="block text-sm font-semibold uppercase tracking-wide text-gray-500 lg:text-sm">Accredited by SA Business School</span>
							<span class="pageheader">
								<span class="block text-gray-900">Introducing the </span>
								<span class="block text-primary-900">SkillsTown Corporate MBA</span>
							</span>
						</h1>
						<div class="pagepara">
							<div>
								eDeaf is partnering with an online skills development platform called SkillsTown. With the launch of SkillsTown’s professional learning platform, all of your goals
								are within reach. Driving inclusive growth and making high-quality skills development a reality, through a combination of first world technology, capability and
								expert knowledge.
							</div>
							<div>
								The SkillsTown e-learning platform specialises in the continuous personal development of corporate employees. As a business professional, you get access to hours of
								business relevant content, designed for high-quality business growth, as well as regular eDeaf content.
							</div>
						</div>
						<div class="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
							<p class="text-base font-medium text-gray-900">Enter your email below to get more information</p>
							<form id="contact" class="mt-3 sm:flex items-center" @submit.prevent="submit">
								<div class="mr-2">
									<TextInput
										autocomplete="on"
										form="contact"
										:maxlength="150"
										:minlength="5"
										name="email"
										placeholder="Email Address"
										type="email"
										v-model="email"
										@update:model-value="emails = $event"
										class=""
									/>
								</div>
								<button @click="sendMail" :disabled="disabled" :class="{ 'opacity-50 cursor-not-allowed': disabled }" class="buttonprimary px-5 py-3">Contact me</button>
							</form>
							<div class="text-xs flex mt-2">
								<div v-if="mailSent" class=""><component :is="statusIcon" class="h-4 px-2 text-primary-900" /></div>
								<div>{{ statusMessage }}</div>
							</div>
							<div @click="showPDF('https://edeafstorage.blob.core.windows.net/media/cMBA%20.pdf')" class="font-medium text-gray-900 underline mt-3 hover:cursor-pointer">
								Download information brochure
							</div>
							<div @click="showPDF('https://edeafstorage.blob.core.windows.net/media/eDeaf%20BBBEE%20Certificate%202023.pdf')" class="font-medium text-gray-900 underline mt-3 hover:cursor-pointer">
								Download BBBEE Certificate
							</div>
						</div>
					</div>
					<div class="relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
						<svg
							class="hidden absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden"
							width="640"
							height="784"
							fill="none"
							viewBox="0 0 640 784"
							aria-hidden="true"
						>
							<defs>
								<pattern id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e" x="118" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
									<rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
								</pattern>
							</defs>
							<rect y="72" width="640" height="640" class="text-gray-50" fill="currentColor" />
							<rect x="118" width="404" height="784" fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)" />
						</svg>
						<div class="">
							<div class="">
								<span class="sr-only">Watch our video to learn more</span>
								<img class="py-3 w-1/2" src="@/assets/images/SkillsTown-Logo.png" alt="" />
							</div>
							<div>
								<video onplay="logPlay()" width="650" controls>
									<source src="https://edeafstorage.blob.core.windows.net/media/Promo-cMBA.mp4" type="video/mp4" />
								</video>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	</div>
</template>

<script>
import TextInput from '@/components/controls/TextInput.vue';
// import ErrorMessages from '@/components/controls/ErrorMessages.vue';
//icons
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/vue/outline';
import axios from 'axios';

export default {
	components: {
		TextInput,
		ExclamationCircleIcon,
		CheckCircleIcon,
	},
	data: () => {
		return {
			valid: true,
			mailSent: false,
			email: '',
			disabled: false,
			statusMessage: '',
			statusIcon: '',
			lazy: false,
		};
	},
	methods: {
		showPDF(path) {
			window.open(path);
		},
		logPlay() {
			this.$gtag.event('skillstown-video-play', { method: 'Google' });
		},
		sendMail() {
			this.disabled = true;

			this.statusMessage = 'Sending mail...';

			this.$gtag.event('skillstown-contact', { method: 'Google' });

			const postData = {
				fromEmail: { key: 'nicky@edeaf.co.za', value: 'Nicky Bezuidenhout' },
				toEmail: { key: 'nicky@edeaf.co.za', value: 'Nicky Bezuidenhout' },
				bccEmail: { key: 'steve@cogni.co.za', value: 'Steve Bezuidenhout' },
				subject: 'eDeaf Website - SkillsTown  Enquiry',
				content: `Email: ${this.email}`,
				source: 'eDeaf.Web',
			};

			let axiosConfig = {
				headers: {
					'Content-Type': 'application/json;charset=UTF-8',
					requestOriginKey: 'AB1BB7D0-B6C4-4BC4-BD11-3E5A48336C0D',
				},
			};

			axios
				.post('https://cognizance-api-web-app.azurewebsites.net/api/web/sendemail', postData, axiosConfig)
				.then((response) => {
					if (response.status == 200) {
						this.statusMessage = 'Thanks for reaching out. We will be in contact soon :)';
						this.statusIcon = 'CheckCircleIcon';
					} else {
						this.statusMessage = 'Something went wrong :( Please contact us via other means.';
						this.statusIcon = 'ExclamationCircleIcon';
					}
					this.mailSent = true;
				})
				.catch(function (error) {
					console.log('error ' + error);
				});
		},
	},
};
</script>
